
import { defineComponent, ref, watch, reactive } from 'vue'
import { ElMessage } from 'element-plus/lib/components'
import httpRequest from '@/service'
import type { ElForm } from 'element-plus'
import { StarFilled } from '@element-plus/icons-vue'

interface IFeed {
  [key: string]: any
}
type FormInstance = InstanceType<typeof ElForm>

export default defineComponent({
  name: '推荐评分',
  props: {
    curColor: {
      type: String,
      default: () => {
        return ''
      }
    },
    id: {
      type: String,
      default: () => {
        return ''
      }
    },
    name: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  setup(props) {
    const value1 = ref(0)
    const ruleFormRef = ref<FormInstance>()
    const ruleForm = reactive<IFeed>({
      detail: ''
    })

    const value = ref()
    const icons = [StarFilled, StarFilled, StarFilled] // same as { 2: ChatRound, 4: { value: ChatLineRound, excluded: true }, 5: ChatDotRound }
    const rules = reactive({
      detail: [
        {
          // required: true,
          // message: '请输入具体建议',
          // trigger: 'blur'
        }
      ]
    })
    const feedBackPost = (
      rate: number,
      details: string,
      id: string,
      name: string
    ) => {
      httpRequest
        .post({
          url: 'recommendReason',
          params: {
            rate: value1.value,
            details: details,
            caseId: id,
            caseName: name
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    // 提交按钮
    const submitClick = async (formEl: FormInstance | undefined) => {
      if (!ruleForm.detail) {
        ElMessage.error('请输入具体建议')
      }
      if (!value1.value) {
        ElMessage.error('请评分')
      }
      if (formEl && value1.value && ruleForm.detail) {
        if (ruleForm.detail.length > 1000) {
          ElMessage.error('推荐理由过长，不能超过10000字')
        } else {
          await formEl.validate((valid) => {
            if (valid) {
              ElMessage({
                message: '感谢您的宝贵评价',
                type: 'success'
              })
              feedBackPost(value1.value, ruleForm.detail, props.id, props.name)
              formEl.resetFields()
              value1.value = 0
            }
          })
        }
      }
    }

    return {
      value1,
      ruleForm,
      rules,
      ruleFormRef,
      icons,
      submitClick,
      StarFilled
    }
  }
})
