
import { defineComponent, ref, reactive, watch } from 'vue'
import { BASE_URL } from '@/service/request/config'

interface Itab {
  [key: string]: any
}
export default defineComponent({
  name: '文书材料',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: ''
    },
    curColor: {
      type: String,
      default: ''
    }
  },
  setup(props: any) {
    const tabState = reactive<Itab>({
      curIndex: 0,
      data: [],
      getData: () => {
        props.data.forEach((item: any) => {
          if (item.type == '') return
          tabState.data.push(item.type)
        })
      },
      handleClick: (index: number) => {
        if (tabState.curIndex === index) return
        tabState.curIndex = index
        filterState.getData(tabState.curIndex)
      }
    })

    const filterState = reactive<Itab>({
      data: {},
      getData: (curIndex: number) => {
        filterState.data = props.data.filter(
          (index: any) => index === props.data[curIndex]
        )[0]
      }
    })
    const pdfUrl = ref('')
    const dialogVisible = ref(false)
    const openDialog = (id: any) => {
      dialogVisible.value = true
      // let url = 'http://192.168.2.46:18082/fzzyk/common/download?checkedId=8979'
      let url = BASE_URL + '/platservice/fileupload/download?' + `ids=${id}`
      pdfUrl.value =
        './static/pdf/web/viewer.html?file=' + encodeURIComponent(url)
    }
    const closeDialog = () => {
      dialogVisible.value = false
    }
    watch(
      () => props.data,
      () => {
        tabState.getData()
        filterState.getData(tabState.curIndex)
      }
    )
    return {
      tabState,
      filterState,
      openDialog,
      dialogVisible,
      closeDialog,
      pdfUrl
    }
  }
})
