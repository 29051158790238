
import {defineComponent} from 'vue'

export default defineComponent({
  name: '案例评析',
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    curColor: {
      type: String,
      default: () => {
        return ''
      }
    }
  }
})
