
import { defineComponent } from 'vue'
export default defineComponent({
  name: '',
  props: {
    data: {
      type: Array,
      default: () => {
        return null
      }
    },
    curColor: {
      type: String,
      default: () => {
        return ''
      }
    }
  }
})
