import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-502beea2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basicInfo" }
const _hoisted_2 = { class: "i-name" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['item', _ctx.curColor])
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(['basicInfos-li', { isHalf: item.isHalf }])
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(item.name), 1 /* TEXT */),
          _createElementVNode("span", {
            innerHTML: item.value,
            class: "i-value"
          }, null, 8 /* PROPS */, _hoisted_3)
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 2 /* CLASS */))
}